import {Constants} from "../constants/constants";

export function setMetadata({ title, backLink, favicon }) {

    return {
        type: Constants.SET_META,
        payload: {
            title,
            backLink,
            favicon
        }

    }
}

export function loading() {
    return { type: Constants.LOADING, loading: true }
}

export function ready() {
    return { type: Constants.LOADING, loading: false }
}

