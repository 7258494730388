import { combineReducers }  from 'redux';
import auth from "./auth";
import global from "./global";
import profession from "./profession";
import app from "./app";
import level from "./level";
import game from "./game";

export default combineReducers({
    auth,
    global,
    profession,
    app,
    level,
    game
})


