import {Alert, Button, Col, Form, Row} from "react-bootstrap"
import React, {useEffect, useState} from "react"
import {AuthService} from "../../service/authService"
import {setMetadata} from "../../store/actions/global"
import {useDispatch} from "react-redux"

export default ({ onChangeView }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [sending, setSending] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        setError(null);
        setSending(true);
        AuthService.forgot(
            email
        ).then((response) => {
            setSending(false);
            if(response.result) {
                setSuccess(true);
            } else {
                setError(response.message);
            }
        })
    }

    useEffect(() => {
        dispatch(setMetadata({
            title: `Forgot password`,
            backLink: `/`
        }));
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (
        <Row className="auth-page">
            <Col xs={12} md={{ span: 6, offset: 3 }}>
                {
                    error && <Alert variant={'danger'}>{error}</Alert>
                }
                {
                    success && <Alert variant={'success'}>We sent an email with the password change link</Alert>
                }
                {
                    !success &&
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    type="email"
                                    placeholder="Enter email" />
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={sending}>
                                { sending ? 'Sending' : 'Send'}
                            </Button>
                            <div>
                                <span className="btn-link" onClick={() => onChangeView('login')}>I remember!!!. Go to Login</span>
                            </div>
                        </Form>
                }

            </Col>
        </Row>
    );
}
