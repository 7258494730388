import React, {useState} from "react";
import "./TimeIndicator.scss"
import icon from "../../assets/images/clock.png"

export default ({ id = 'timer', name = "timer", onChange }) => {

    const [currentValue, setCurrentValue] = useState(JSON.parse(localStorage.getItem('with-time')) || false)


    const handleChange = e => {
        const checked = JSON.parse(e.target.checked)
        localStorage.setItem('with-time', checked)
        setCurrentValue(checked)
        if (typeof onChange === "function") onChange(checked);
    }

    return (
        <div className="timer-indicator">
            <img className="clock" src={icon} alt="clock" title=""/>
            <div className={"toggle-switch"}>
                <input
                    type="checkbox"
                    name={name}
                    id={id}
                    className="toggle-switch-checkbox"
                    checked={currentValue}
                    onChange={handleChange}
                />
                <label className="toggle-switch-label" htmlFor={id}>
                    <span
                        className="toggle-switch-inner"
                        data-yes={"On"}
                        data-no={"Off"}
                    />
                    <span className="toggle-switch-switch"/>
                </label>
            </div>
        </div>
    );
}