import {Constants} from "../constants/constants";
import {ProfessionService} from "../../service/professionService"
import {setAction} from "./fetchActions"

export function getAll() {
    return (dispatch) => {
        dispatch(setAction(Constants.PROFESSION_BEGIN))

        return ProfessionService.getAll()
            .then((json) => dispatch(setAction(Constants.PROFESSION_SUCCESS, json)))
            .catch((error) => dispatch(setAction(Constants.PROFESSION_FAILURE, error)))
    }
}
