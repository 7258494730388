import {Constants} from "../constants/constants";
import {setAction} from "./fetchActions"
import {AppService} from "../../service/appService"


export function findByProfession(professionSlug) {
    return (dispatch) => {
        dispatch(setAction(Constants.APP_BEGIN))

        return AppService.findBySlug(professionSlug)
            .then((json) => dispatch(setAction(Constants.APP_SUCCESS, json)))
            .catch((error) => dispatch(setAction(Constants.APP_FAILURE ,error)))
    }
}
