import {Constants} from "../constants/constants";

export const initialState = {
    title: '',
    loading: false,
    backLink: '',
    favicon: 'files/painting-icon.ico'
};

export default function global(state = initialState, action) {
    switch (action.type) {

        case Constants.SET_META:
            Object.keys(action.payload).filter(k => action.payload[k] === undefined).forEach(k => delete(action.payload[k]));//remove undefined values
            return Object.assign({}, state, action.payload)

        default:
            return state
    }
}
