import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import '../sass/home.scss'
import {setMetadata} from "../store/actions/global"
import {getAll} from "../store/actions/profession"
import ImageOption from "../components/global/ImageOption"

export default () => {
    const dispatch = useDispatch();

    const state = useSelector(state => state.profession);

    const { professions, loading } = state;

    useEffect(() => {
        dispatch(getAll())
        dispatch(setMetadata({
            title: 'Mandagames',
            backLink: null,
            favicon: 'files/painting-icon.ico'
        }))
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    if(loading) {
        return null;
    }

    return (
        <div className="home-page">
            <ol className="row list-unstyled text-center">
                {professions.map((profession, idx) => {
                    return (
                        <li key={idx} className="col profession-option">
                            <ImageOption src={profession.splash} title={profession.name} link={`/app/${profession.slug}`}/>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
}
