import {Alert, Button, Col, Form, Row} from "react-bootstrap"
import React, {useEffect, useState} from "react"
import {AuthService} from "../../service/authService"
import {login} from "../../store/actions/auth"
import {useDispatch, useSelector} from "react-redux"
import {setMetadata} from "../../store/actions/global"
import {useHistory} from "react-router-dom"

export default ({ onChangeView }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [error, setError] = useState(null);
    const {tmpEmail} = useSelector(state => state.auth);
    const [code, setCode] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        AuthService.verify(
            tmpEmail,
            code
        ).then((response) => {
            if(response.result) {
                dispatch(login(response.token, response.user));
                history.push('/');
            } else {
                setError(response.message);
            }
        })
    }

    useEffect(() => {
        dispatch(setMetadata({
            title: `Verification`,
            backLink: `/`
        }));
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (
        <Row className="auth-page">
            <Col xs={12} md={{ span: 6, offset: 3 }}>
                {
                    error && <Alert variant={'danger'}>{error}</Alert>
                }
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                            value={code}
                            onChange={e => setCode(e.target.value)}
                            type="code"
                            placeholder="Enter code" />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Send
                    </Button>

                    <div>
                        <span className="btn-link" onClick={() => onChangeView('forgot')}>You still haven't received the code, send again</span>
                    </div>
                </Form>
            </Col>
        </Row>
    );
}
