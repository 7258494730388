import {Network} from "./Network"

export class AuthService {
    static checkEmail (data) {
        return Network.post(false,`/check-email`, data)
    }

    static googleLogin (data) {
        return Network.post(false,`/google-login`, data)
    }

    static facebookLogin (data) {
        return Network.post(false,`/facebook-login`, data)
    }

    static login (data) {
        return Network.post(false,`/login`, data)
    }

    static register (data) {
        return Network.post(false,`/register`, data)
    }

    static saveProfile (data) {
        return Network.post(true,`/save-profile`, data)
    }

    static verify (email, code) {
        return Network.post(false,`/validate`, {email, code})
    }

    static forgot (email) {
        return Network.post(false,`/forgot`, {email})
    }

    static changePassword (data) {
        return Network.post(false,`/change-password`, data)
    }

    static getProfile () {
        return Network.post(true,`/profile`, {})
    }
}
