import Image from "./Image"
import {Link} from "react-router-dom"
import React from "react"
import './ImageOption.scss'

export default ({ link, title, src}) => {
    return (
        <Link className={"image-option"} to={link} title={title}>
            <Image src={src} alt={title}/>
            <h3>{title}</h3>
        </Link>
    );
}
