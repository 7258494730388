import React from "react";

export default () => {
    return (
        <section>
            <p>POLICY ON DATA PROTECTION AND CONFIDENTIALITY<br/>
                MANDAGAMES (hereinafter the
                Company) is committed according to the law on the protection of personal data under Argentine Law, the
                international treaties and agreements signed by Argentina, to take all necessary measures for the protection
                of the subscribers’ personal data and the respect of their private life. This document aims to inform
                Company’s subscribers about their rights and about the Company’s data protection policy in connection with
                any Company’s games or applications (hereinafter “Services”). Please note that the scope of this Privacy
                Policy is limited only to information collected by the Company through your use of the Services. By using
                the Services, you agree to the collection and use of your personal information as outlined in this Privacy
                Policy.<br/>
                Company may amend the Privacy Policy from time to time and encourages the subscribers to consult
                the Privacy Policy regularly for changes.<br/>
                WHICH INFORMATION IS PERSONAL DATA<br/>
                Personal Data is any
                information relating to a live identified natural person or an identifiable natural person who can
                be identified, directly or indirectly, in particular by reference to an identification number or to
                one or more factors specific to his identity.<br/>
                When you browse the Company’s Services, the Company
                may collect information regarding the domain and host from which you access the Internet, the
                Internet Protocol addresses of the computer or Internet Service Provider you are using, and
                anonymous site statistical data. This information is collected for the purpose of assessing the
                effectiveness of the company’s Service and for security reasons.<br/>
                We also gather store and
                process information in order to be able to provide you with better services and customized
                information and communication. For this purpose, we use so-called cookies in several places.
                The cookies serve to make our service more user-friendly, effective and secure. Cookies are
                small text files that are filed on your computer and that your browser stores. We use
                persistent cookies to determine whether there has been communication in the past between
                your computer and us. Persistent cookies stay on your hard disk until they are deleted or
                expire automatically. Most browsers automatically accept cookies. You can prevent cookies
                from being stored on your hard disk by changing your browser settings to “do not allow
                cookies”. For detailed instructions on how to do this, please consult your browser manual.
                You may delete cookies that are stored on your hard disk at any time.<br/>
                Disallowing cookies
                can negatively affect the performance of features provided by this online service. We
                therefore recommend allowing cookies. This Service uses Google Inc. technology to
                collect and store in anonymous form information on the surfing behavior of visitors to
                this site for marketing purposes. The data stored in cookies is analyzed using an
                algorithm so that product recommendations in the form of web banners and advertising can
                be displayed on third party Services.<br/>
                Please note that in addition to the information
                collected as described above, you may choose to share additional about yourself
                throughout the Services, such as on message boards and in games and in that case you
                have no expectations of privacy. Any actions you perform with other players are
                considered public, and are in your sole discretion.<br/>
                You should be aware that any
                personally identifiable information you submit in the course of these public
                activities can be read, collected, or used by other users of these forums, and
                could be used in order to be sent unsolicited messages. The Company is not
                responsible for the personally identifiable information you choose to make
                public in any of these forums.<br/>
                If you post a video, image or photo through a
                Service for public view, you should be aware that these may be viewed,
                collected, copied and/or used by other users without his consent. The
                Company is not responsible for the videos, images or photos that Subscribers
                choose to post through a Service. Please see Company’s Terms of Service at
                www.mandagames.com/TERMS.htm or the Application End User License Agreement
                at <a href="https://www.mandagames.com/DATAPOLICY.htm">https://www.mandagames.com/DATAPOLICY.htm</a> (as
                applicable) on this point and for other guidelines about posting content
                through any Service.<br/>
                LINKS<br/>
                The Services may contain links to other
                websites or applications. The fact that the Services links to a website
                or application or presents a banner ad or other type of advertisement
                does not mean that the Company endorses or authorizes that third party,
                nor is it a representation of any affiliation with that third party. If
                you click on a link to a third party site or application, including on
                an advertisement, you will leave Company’s site and you visit the site
                or application linked to. These third party websites and applications
                may place their own cookies or other files on your computer, and may
                collect data or solicit personal information from you. Other sites and
                applications follow different rules regarding the use or disclosure of
                the personal information you submit to them. Because the Company cannot
                control the activities of third parties, it cannot accept responsibility
                for any use of your personal information by such third parties, and it
                cannot guarantee that they will adhere to the same privacy and security
                practices as the Company. Any subscriber therefore is encouraged to read
                the privacy policies or statements of other websites and applications he
                visits before providing any personal information.<br/>
                HOW WE PROTECT AND
                STORE INFORMATION<br/>
                We store and process your information on our
                servers, and we protect it by maintaining physical, electronic
                and procedural safeguards in compliance with the applicable law.
                We use computer safeguards such as firewalls and data
                encryption, we enforce physical access controls to our buildings
                and files, and we authorize access to information only for those
                employees who require it to fulfill their job responsibilities.
                The Company shall inform its subscribers in the case that their
                personal data is legally transferred to third parties.<br/>
                The
                subscribers are obliged to protect all the confidential data
                that are granted to them. Access codes and secret codes are
                strictly personal and the Company shall not be responsible
                in case of loss or theft. In case of loss or theft of the
                above codes, the subscribers are obliged to inform the
                Company as soon as possible.<br/>
                ACCESS THROUGH SOCIAL
                NETWORK SERVICES<br/>
                When you access the Services through
                a social network or other third party’s platform or
                service or by connecting to such a third party
                network, you are authorizing the Company to collect,
                store and use in accordance with this Privacy Policy
                any and all information that they agreed the social
                network or other third party platform could provide
                to the Company through the social network/ third
                party’s platform based on your settings on the third
                party’s social network/ third party’s platform. Your
                agreement takes place when you connect with the
                third party’s network or platform via the Services,
                or when you connect with the Services or “accept” or
                “allow” Services to access his information through a
                social network or third party’s platform or service.<br/>
                The
                Company may also collect or receive information
                about you from other Services users who choose
                to upload their mail contacts. This information
                will be stored by the Company and used primarily
                to help the connection between friends.<br/>
                HOW
                WE SHARE INFORMATION WITH OTHER PARTIES<br/>
                We
                may share information with:<br/>
                Law
                enforcement, Financial Supervision
                Authorities, government officials,
                or other third parties when we are
                compelled to do so by a subpoena,
                court order or similar legal
                procedures<br/>We need to do so to
                comply with law<br/>We believe in
                good faith that the
                disclosure of personal
                information is necessary to
                prevent physical harm or
                financial loss, to report
                suspected illegal activity,
                or for investigations.<br/>The
                Company will not sell or
                rent any of your
                information to third
                parties for their
                marketing purposes and
                only shares your
                personal information
                with third parties as
                described in this
                policy.<br/>CHANGING OR
                DELETING PERSONAL
                DATA<br/>In all cases
                of data access
                and collection,
                the information
                the subscribers
                provide will not
                be disclosed,
                rented, loaned,
                leased, sold or
                otherwise
                voluntarily
                distributed to
                an unaffiliated
                third parties
                and will be used
                solely for the
                purpose of
                providing you
                with Services.
                If any
                Subscriber wish
                to cancel his
                account or
                request the
                Company no
                longer use his
                information
                should contact
                the Company at
                the mail address
                provided in the
                address below
                (“contact
                us”).<br/>POLICY
                REGARDING
                PERSONS
                UNDER 18<br/>The
                Services
                are
                intended
                for use
                by those
                18 or
                older
                for
                amusement
                purposes
                only.
                Without
                derogating
                from the
                foregoing,
                the
                Company
                does not
                knowingly
                collect
                personal
                information
                from
                people
                under
                the age
                of 13.
                If the
                Company
                learns
                that a
                person
                under
                the age
                of 13
                has
                provided
                it with
                personal
                information,
                the
                Company
                will
                take
                steps to
                delete
                such
                information
                as soon
                as
                possible.
            </p>
            <p>CONTACT US<br/>
            The Company’s subscribers have the right to be informed in writing about the purposes of
                processing of their personal data, by applying to the following address: <a
                    href="mailto:info@mandagames.com">info@mandagames.com</a>
            </p>
        </section>
    );
}