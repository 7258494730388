import React, {useEffect, useState} from "react"
import Image from "../global/Image"
import {useDispatch, useSelector} from "react-redux"
import {addHint, hover, select, play, stop} from "../../store/actions/game"
import {useParams} from "react-router"
import {isMobile} from "../../helpers/device"
import {isPlaying} from "../../helpers/soundEffects"

export default ({ artWork, selected }) => {

    const dispatch = useDispatch();
    const state = useSelector(state => state.game);
    let { gameSlug } = useParams();

    const { hints, artWorksLoading, hoverArtWorkId, currentTrack } = state;

    const showHint = hints.includes(artWork.id)
    const showLoading = artWorksLoading.includes(artWork.id)

    const [lastEvent, setLastEvent] = useState(null);

    const isPlayingCurrent = () => {
        return (currentTrack?.artWorkId === artWork.id) && isPlaying(currentTrack?.audio)
    }

    useEffect(() => {
        if(artWork.id === currentTrack?.artWorkId && currentTrack?.audio) {
            currentTrack.audio.addEventListener("ended", function(a) {
                dispatch(stop())
            })
        }
    }, [dispatch, artWork, currentTrack])

    const onClick = (e) => {
        if(selected || showLoading) return;
        e.stopPropagation();

        dispatch(hover(artWork));
        if(isPlayingCurrent()) {
            dispatch(stop(artWork));
        } else {
            dispatch(play(artWork));
        }
    }
    const onSelect = (e) => {
        if(selected || showLoading) return;
        e.stopPropagation();

        if(isMobile()) {
            if(hoverArtWorkId === artWork.id){
                dispatch(select(gameSlug, artWork.id));
            } else {
                dispatch(hover(artWork));
                dispatch(play(artWork));
            }
        } else {
            dispatch(select(gameSlug, artWork.id));
        }

    }

    const hint = (e) => {
        if(showLoading) return;
        e.stopPropagation();
        dispatch(addHint(gameSlug, artWork.id));
    }

    useEffect(() => {
        return () => {
            if(showHint) {
                clearTimeout(showHint);
            }
        }
    }, [showHint]);

    const onTouch = (e) => {
        e.stopPropagation();
        if(isMobile()) return;

        if(e.type === 'touchend' && lastEvent === 'touchstart') {
            if(hoverArtWorkId !== artWork.id) {
                dispatch(hover(artWork));
            } else {
                dispatch(select(gameSlug, artWork.id));
            }
        }

        setLastEvent(e.type)
        if(e.type === 'touchend' && hoverArtWorkId !== artWork.id){
            dispatch(hover(artWork));
        }

    }

    const onPlay = (e) => {
        e.stopPropagation();
        dispatch(play(artWork));
    }

    const onStop = (e) => {
        e.stopPropagation();
        dispatch(stop(artWork));
    }

    const onHover = (e) => {
        if(!isMobile()) {
            e.stopPropagation();
            if(hoverArtWorkId !== artWork.id) {
                dispatch(hover(artWork))
                if(currentTrack?.artWorkId !== artWork.id) {
                    dispatch(play(artWork))
                }
            }
        }
    }

    return (
        <>
            <div
                onTouchEnd={onTouch}
                onTouchMove={onTouch}
                onTouchStart={onTouch}
                onMouseOver={onHover}
                onClick={onClick}
                className={`game-option col-md-4 col-xl-3 col-sm-4 col-6 ${selected && selected.type.toLowerCase()} ${(hoverArtWorkId === artWork.id) ? 'hover' : ''}`}
            >
                <div className="artwork-option">
                    <Image title={artWork.title} src={artWork.image} alt={artWork.title}/>
                </div>
                <h6 className="artwork-title" title={artWork.title}>{artWork.title}</h6>
                <div className="artwork-details">{artWork.date ? artWork.date : '-'}</div>
                { !showLoading && <div className="artwork-details">&nbsp;</div> }
                {
                    showHint &&
                    <div className="artwork-hint-result">
                        <i className="fa fa-lightbulb-o"/>
                        <strong className="">{' '}{artWork.artist.nick}</strong>
                    </div>
                }
                {
                    !showLoading &&
                    <div className="artwork-action">
                        <span onClick={(e) => onSelect(e)} className="action check"><i className="fa fa-check"/></span>
                        {
                            isPlayingCurrent() &&
                            <span onClick={(e) => onStop(e)} className="action"><i
                                className="fa fa-stop"/></span>
                        }
                        {
                            !isPlayingCurrent() &&
                            <span onClick={(e) => onPlay(e)} className="action"><i
                                className="fa fa-play"/></span>
                        }
                        <span onClick={(e) => hint(e)} className="action hint"><i className="fa fa-lightbulb-o"/></span>
                    </div>
                }

                <span className="icon-failed"><i className="fa fa-times-circle fa-4"/></span>
                <span className="icon-selected"><i className="fa fa-check-circle fa-4"/></span>
                { showLoading && <span className="icon-loading"><i className="fa fa-spinner fa-spin fa-4"/></span> }
            </div>
        </>
    );
}
