import React from "react";

export default () => {
    return (
        <section>
            <p>
                mandagames (music and art games) are casual social games about masterpieces of classical music and Old Master painting for web, facebook and mobile.
            </p>
            <p>
                What sets our games apart is their subject matter and how they address it: "serious" arts are presented as amusing and competitive games.
            </p>
            <p>
                Players of casual facebook games (cow-clickers, bubble-shooters) benefit from having fun, beating friends, posting achievements. Our games add exposure to fine arts, the joy and educational value of seeing/hearing masterworks and an appeal to "cultural snobbery" as in "do you know Mozart better than I do?"
            </p>
            <p>
                We require users to sign-in so that we can store their score, map their progress and post their achievements.
            </p>
        </section>
    );
}
