import React from "react"
import {Col, Container, Row} from "react-bootstrap"
import {CountdownCircleTimer} from "react-countdown-circle-timer"
import GameResult from "./GameResult"
import {getResultArtWorks} from "../../helpers/game"
import {Link} from "react-router-dom"
import Image from "../global/Image"
import {timeout} from "../../store/actions/game"
import {useDispatch, useSelector} from "react-redux"
import {ProfessionHelper} from "../../helpers/profession"
import GamePictureOption from "./GamePictureOption"
import GameMusicOption from "./GameMusicOption"

export default ({ game }) => {
    const dispatch = useDispatch();
    const globalState = useSelector(state => state.global)

    const {title, backLink} = globalState;

    const handleTimeout = () => {
        dispatch(timeout(game.slug));
    }

    if(['TIMEOUT', 'LOSE', 'WIN'].includes(game.status)) {
        return <GameResult game={game}/>;
    }

    const cardData = getResultArtWorks(game);

    return (
        <>
            <div className="game-status">
                <Link to={backLink} id="back-link">
                    <i className="fa fa-chevron-circle-left"/>
                </Link>
                <div className="lives d-none d-sm-block">
                    {
                        [1,2,3,4].map((live) => {
                            return live <= game.lives ?
                                <i key={live} className="hidden-xs fa fa-heart lives-hearts"/> :
                                <i key={live} className="hidden-xs fa fa-heart-o lives-hearts"/>
                        })
                    }
                </div>
                <div className="lives lives-small d-block d-sm-none">
                    <i className="hidden-xs fa fa-heart lives-hearts"/>
                    <span>{game.lives}</span>
                </div>
                <div className="game-title" dangerouslySetInnerHTML={{ __html: title }}/>
                {
                    game.withTime &&
                    <div className="count-down-container">
                        <CountdownCircleTimer
                            onComplete={handleTimeout}
                            initialRemainingTime={game.level.timer - game.timeLeft}
                            isPlaying
                            size={20}
                            strokeWidth={2}
                            duration={game.level.timer}
                            colors="#A30000"
                        />
                    </div>
                }

                <div className="score">
                    <i className="fa fa-star"/>
                    <span className="score-point">{game.points}</span>
                </div>

            </div>
            <Container>
                <Row className="game-content">
                    {
                        game.artWorks.map((artWork, idx) => {
                            const selected = game.selected.find((item) => item.artWorkId === artWork.id) || null
                            return ProfessionHelper.isMusic(game.profession.id) ?
                                <GameMusicOption key={idx} artWork={artWork} selected={selected}/> :
                                <GamePictureOption key={idx} artWork={artWork} selected={selected}/>
                        })
                    }
                </Row>
                <Row className='selected-artworks'>
                    {
                        cardData.map((artWork, idx) => {
                            if(artWork) {
                                return (
                                    <Col key={idx} xs={3}>
                                        <div className="selected-artwork">
                                            <Image alt={artWork.name} src={artWork.image} title={artWork.name} />
                                        </div>
                                    </Col>
                                );
                            } else {
                                return (
                                    <Col key={idx} xs={3}>
                                        <div className="selected-artwork">
                                            <span className="ng-binding ng-scope">{idx +1 }</span>
                                        </div>
                                    </Col>
                                );
                            }
                        })
                    }
                    <div id="help-link">
                        <Link to="/content/help" target="_blank"><i className="fa fa-question-circle"/></Link>
                    </div>

                </Row>
            </Container>
        </>
    );
}
