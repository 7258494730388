import React, {useState} from "react"
import {GameService} from "../../service/gameService"
import { useHistory } from "react-router-dom";
import {useParams} from "react-router"


export default ({ level, app }) => {
    const [isOpen, setIsOpen] = useState(false);
    let history = useHistory();
    let { professionSlug, appSlug } = useParams();

    const initGame = (level, artist)  => {
        GameService.initGame(appSlug, level, artist).then((response) => {
            if(response?.game) {
                history.push(`/app/${professionSlug}/${appSlug}/${response.game.slug}`, response.game);
            }
        })
    }

    return (
        <>
            <div className="level-btn btn btn-primary" onClick={() => setIsOpen(!isOpen)}>
                {level.name} {' '}
                {
                    isOpen ? <i className="fa fa-chevron-down"/> : <i className="fa fa-chevron-right"/>
                }
            </div>
            <div className={'artist-list ' + (isOpen ? '' : 'd-none')}>
                {
                    level.artists.map((artist, idx) => {
                        return (
                            <div onClick={() => initGame(level.id, artist.id)} className="btn btn-primary" key={idx}>
                                {artist.nick}
                                <span className="score">{artist.score}</span>
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
}
