import {Constants} from "../constants/constants";
import {setAction} from "./fetchActions"
import {GameService} from "../../service/gameService"
import { play as soundPlay} from "../../helpers/soundEffects"

export function findBySlug(slug) {
    return (dispatch) => {
        dispatch(setAction(Constants.GAME_BEGIN))

        return GameService.getBySlug(slug)
            .then((json) => dispatch(setAction(Constants.GAME_SUCCESS, json)))
            .catch((error) => dispatch(setAction(Constants.GAME_FAILURE ,error)))

    }
}

export function addHint(slug, artWorkId) {
    return (dispatch) => {
        dispatch(setAction(Constants.GAME_HINT_INIT, artWorkId))

        return GameService.addHint(slug, artWorkId)
            .then((json) => {
                setTimeout(() => {
                    dispatch(setAction(Constants.GAME_HINT_HIDE, artWorkId))
                }, 4000)
                dispatch(setAction(Constants.GAME_HINT_SUCCESS, {
                    artWorkId,
                    game: json.game
                }))
            })
            .catch((error) => dispatch(setAction(Constants.GAME_FAILURE ,error)))

    }
}

export function hover(artWork) {
    return (dispatch) => {
        return dispatch({
            type: Constants.GAME_HOVER,
            artWork: artWork
        })
    }
}

export function play(artWork) {
    return (dispatch) => {
        setTimeout(() => {
            soundPlay(`${process.env.REACT_APP_CDN}/${artWork.track}`).then((audio) => {
                return dispatch({
                    type: Constants.GAME_PLAY,
                    artWork: artWork, audio
                })
            })
        }, 300)
    }
}

export function stop() {
    return (dispatch) => {
        return dispatch({
            type: Constants.GAME_STOP
        })
    }
}

export function timeout(gameSlug) {
    return (dispatch) => {

        return GameService.timeout(gameSlug)
            .then((json) => dispatch(setAction(Constants.GAME_TIMEOUT, json)))
            .catch((error) => dispatch(setAction(Constants.GAME_FAILURE ,error)))
    }
}

export function select(slug, artWorkId) {
    return (dispatch) => {
        dispatch(setAction(Constants.GAME_SELECTED_INIT, artWorkId))

        return GameService.select(slug, artWorkId)
            .then((json) => dispatch(setAction(Constants.GAME_SELECTED_SUCCESS, {
                artWorkId,
                ...json
            })))
            .catch((error) => dispatch(setAction(Constants.GAME_FAILURE , {
                artWorkId,
                error
            })))

    }
}
