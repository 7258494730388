import React from "react"
import {Link} from "react-router-dom"

export default () => {
    return (
        <footer className="footer">
            <div className="image-courtesy">
                <small>Images courtesy of <a rel='noopener noreferrer' href="http://www.wga.hu" target="_blank">www.wga.hu</a></small>
            </div>
            <div className="footer-text">
                <ul>
                    <li><Link to={'/content/terms-of-use'}>Terms of use and Privacy Policy</Link></li>
                    <li><Link to={'/content/what-is-mandagames'}>What is mandagames?</Link></li>
                </ul>
            </div>
        </footer>
    );
}
