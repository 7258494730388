import {Alert, Button, Col, Form, Row} from "react-bootstrap"
import React, {useEffect, useState} from "react"
import {AuthService} from "../../service/authService"
import {useDispatch} from "react-redux"
import {setTmpEmail} from "../../store/actions/auth"
import {setMetadata} from "../../store/actions/global"

export default ({ onChangeView }) => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [nick, setNick] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [sending, setSending] = useState(false);


    const handleSubmit = (event) => {
        event.preventDefault();

        setError(null);
        setSending(true);
        AuthService.register({
            email,
            nick,
            password,
            name
        }).then((response) => {
            setSending(false);
            if(response.result) {
                dispatch(setTmpEmail(response.user.email));
                onChangeView('verification')
            } else {
                setError(response.message);
            }
        })
    }

    useEffect(() => {
        dispatch(setMetadata({
            title: `Register`,
            backLink: `/`
        }));
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (
        <Row className="auth-page">
            <Col xs={12} md={{ span: 6, offset: 3 }}>
                {
                    error && <Alert variant={'danger'}>{error}</Alert>
                }
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            type="email"
                            placeholder="Enter email" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            value={name}
                            onChange={e => setName(e.target.value)}
                            type="text"
                            placeholder="Enter name" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Nick</Form.Label>
                        <Form.Control
                            value={nick}
                            onChange={e => setNick(e.target.value)}
                            type="text"
                            placeholder="Enter nickname" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            placeholder="Password" />
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={sending}>
                        { sending ? 'Sending' : 'Register'}
                    </Button>
                    <div>
                        <span className='btn-link' onClick={() => onChangeView('login')}>Already registered. Go to Login</span>
                    </div>
                </Form>
            </Col>
        </Row>
    );
}
