import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import '../../sass/leaderboard.scss'
import {LeaderBoardService} from "../../service/leaderBoardService";
import {Table} from "react-bootstrap";

export default () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [leaderBoard, setLeaderBoard] = useState([]);


    useEffect(() => {
        setLoading(true);

        async function fetchData() {
            const result = await LeaderBoardService.getLeaderBoard();
            setLoading(false);
            setLeaderBoard(result.leaderBoard);
        }
        fetchData();

    }, [dispatch]);

    if(loading) {
        return null;
    }

    return (
        <Table variant="dark">
            <thead>
                <tr>
                    <th>Nick</th>
                    <th>Score</th>
                </tr>
            </thead>
            <tbody>
            {leaderBoard.map((row, idx) => {
                return (
                    <tr key={idx}>
                        <td>{row.user.nick}</td>
                        <td>{row.score}</td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
    );
}
