import {Constants} from "../constants/constants";

export const getToken = () => {
    return localStorage.getItem('token');
};

export const setToken = (token) => {
    return localStorage.setItem('token', token);
};

export const removeToken = () => {
    return localStorage.removeItem('token');
};

export function logout() {
    return { type: Constants.AUTH_LOGOUT }
}
export function login(token, user) {
    return { type: Constants.AUTH_LOGIN, token, user }
}
export function setUser(user) {
    return { type: Constants.AUTH_SET_USER, user }
}
export function setTmpEmail(email) {
    return { type: Constants.AUTH_SET_TMP_EMAIL, email }
}
