import {API} from "aws-amplify";
import awsConfig from "../aws-exports"
import {getToken, logout} from "../store/actions/auth"
import store from '../store/store'

export class Network {

    static getQuery = async (query) => {
        return Object.assign({}, query, {
            clientDate: Date.now()
        });
    }

    static processError = (error) => {
        if(error.response?.status === 401) {
            store.dispatch(logout());
        }
    }

    static post = async (isPrivate, path, data, query = {}) => {
        return API.post(awsConfig.aws_cloud_logic_custom[0].name, `/${isPrivate ? 'private' : 'public'}/v1${path}`, {
            queryStringParameters: await this.getQuery(query),
            body: data,
            headers: {
                Authorization: getToken(),
            }
        }).catch(Network.processError)
    }

    static fetch = async (isPrivate, path, query = {}) => {
        return await API.get(awsConfig.aws_cloud_logic_custom[0].name, `/${isPrivate ? 'private' : 'public'}/v1${path}`, {
            queryStringParameters: await this.getQuery(query),
            headers: {
                Authorization: getToken(),
            }
        }).catch(Network.processError)

    };
}

