import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import Image from "../components/global/Image"
import {setMetadata} from "../store/actions/global"
import { useParams} from "react-router";
import {findByApp} from "../store/actions/level"
import {Col, Row} from "react-bootstrap"
import LevelButton from "../components/level/LevelButton"
import TimeIndicator from "../components/global/TimeIndicator";

export default () => {
    const dispatch = useDispatch();
    let { professionSlug, appSlug } = useParams();

    const state = useSelector(state => state.level);

    const { levels, app } = state;

    useEffect(() => {
        dispatch(findByApp(appSlug));
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);


    useEffect(() => {
        dispatch(setMetadata({
            title: app?.name ?? 'Mandagames',
            backLink: `/app/${professionSlug}`
        }));
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [app]);

    return (
        <div className="level-page">
            <Row className="image-container">
                <Col className="text-center">
                    <TimeIndicator/>
                    { app && <Image title={app.name} src={app.splash} alt={app.name}/> }
                </Col>
            </Row>
            <Row className="levels-container">
            {
                levels.map((level, idx) => {
                    return (
                        <Col md={4} key={idx}>
                            <LevelButton app={app} level={level}/>
                        </Col>
                    );
                })
            }
            </Row>
        </div>
    );
}
