import React from "react";
import {Col, Row} from "react-bootstrap"
import { useSelector } from 'react-redux'
import {Link} from "react-router-dom"
import UserWidget from "./UserWidget";


export default () => {
    const globalState = useSelector(state => state.global)

    const {title, backLink} = globalState;

    return (
        <header className="header">
            <Row>
                <Col xs={{ span: 2 }}>
                    {
                        backLink &&
                        <Link to={backLink} id="back-link">
                            <i className="fa fa-chevron-circle-left"/>
                        </Link>
                    }
                </Col>
                <Col xs={{ span: 8 }}>
                    <h1 className="headline" dangerouslySetInnerHTML={{ __html: title }}/>
                </Col>
                <Col xs={{span: 2}}>
                    <UserWidget/>
                </Col>
            </Row>
        </header>
    );
}
