export const getResultArtWorks = (game) => {
    const artistWorkArts = game.artWorks.filter((artWork) => artWork.artist.id === game.artist.id);

    const selectedArtWorks = artistWorkArts.filter((artWork) => {
        return game.selected
            .map((selected) => (selected.type === 'SELECTED') ? selected.artWorkId : null )
            .filter(n => n)
            .includes(artWork.id);
    })

    const cardData = [];
    [...Array(artistWorkArts.length).keys()].forEach((i) =>
        cardData.push((selectedArtWorks.length <= i) ? null : selectedArtWorks[i])
    )

    return cardData;
}
