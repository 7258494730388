import {Constants} from "../constants/constants";
import {FAIL, play, SELECTED, WIN, LOSE, stop} from "../../helpers/soundEffects"

export const initialState = {
    game: null,
    hints: [],
    artWorksLoading: [],
    hoverArtWorkId: null,
    currentTrack: null
};

export default function global(state = initialState, action) {
    let artWorksLoading = [];
    let index = null;
    let hints = null;

    switch (action.type) {

        case Constants.GAME_BEGIN:
            return { ...state, ...initialState }

        case Constants.GAME_SUCCESS:
            return { ...state, game: action.payload.game }

        case Constants.GAME_PLAY:
            state.currentTrack && stop(state.currentTrack.audio);

            return { ...state, currentTrack: { artWorkId: action.artWork.id, audio: action.audio} }

        case Constants.GAME_STOP:
            state.currentTrack && stop(state.currentTrack.audio);
            return { ...state, currentTrack: null }

        case Constants.GAME_HOVER:
            return { ...state, hoverArtWorkId: action.artWork.id }

        case Constants.GAME_HINT_INIT:
            artWorksLoading = [...state.artWorksLoading];
            artWorksLoading.push(action.payload)
            return { ...state, artWorksLoading }

        case Constants.GAME_SELECTED_INIT:
            artWorksLoading = [...state.artWorksLoading];
            artWorksLoading.push(action.payload)
            return { ...state, artWorksLoading }

        case Constants.GAME_TIMEOUT:
            //state?.currentTrack?.audio && stop(state.currentTrack.audio)
            play(LOSE);
            return { ...state, game: action.payload.game }

        case Constants.GAME_HINT_SUCCESS:
            artWorksLoading = [...state.artWorksLoading];
            index = artWorksLoading.indexOf(action.payload.artWorkId);
            if (index > -1) {
                artWorksLoading.splice(index, 1);
            }

            hints = [...state.hints];
            hints.push(action.payload.artWorkId)
            return { ...state, game: action.payload.game, hints, artWorksLoading }

        case Constants.GAME_FAILURE:
            artWorksLoading = [...state.artWorksLoading];
            index = artWorksLoading.indexOf(action.payload.artWorkId);
            if (index > -1) {
                artWorksLoading.splice(index, 1);
            }
            return { ...state, artWorksLoading }
        case Constants.GAME_SELECTED_SUCCESS:
            artWorksLoading = [...state.artWorksLoading];
            index = artWorksLoading.indexOf(action.payload.artWorkId);
            if (index > -1) {
                artWorksLoading.splice(index, 1);
            }

            switch (action.payload.result) {
                case 'SELECTED':
                    play(SELECTED);
                    break;
                case 'FAILED':
                    play(FAIL);
                    break;
                case 'WIN':
                    play(SELECTED);
                    play(WIN);
                    break;
                case 'LOSE':
                    play(FAIL);
                    play(LOSE);
                    break;
                default:
                    break;
            }

            return { ...state, game: action.payload.game, artWorksLoading }

        case Constants.GAME_HINT_HIDE:
            hints = [...state.hints];
            index = hints.indexOf(action.payload);
            if (index > -1) {
                hints.splice(index, 1);
            }
            return { ...state, hints }

        default:
            return state;
    }
}
