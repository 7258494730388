import {Col, Row} from "react-bootstrap"
import React from "react"
import { useDispatch } from 'react-redux'
import {setMetadata} from "../store/actions/global"

export default (props) => {
    const dispatch = useDispatch();
    dispatch(setMetadata({
        title: '404 - Not Found',
        backLink: '/'
    }))
    return (
        <Row>
            <Col>Error 404</Col>
        </Row>
    );
}
