import Header from "./Header"
import {Container} from "react-bootstrap"
import React, {useEffect} from "react"
import { Helmet } from "react-helmet-async"
import {useSelector} from "react-redux"
import Footer from "./Footer"

export default ({ children, ...rest }) => {
    const { title, favicon } = useSelector(state => state.global);

    useEffect(() => {
        document.getElementById("favicon").href = `${process.env.REACT_APP_CDN}/${favicon}`;
    }, [favicon])

    return (
        <div className={`main-container profession-${rest.match?.params?.professionSlug}`}>
            <Container>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <Header/>
                <div className="content">
                    {children}
                </div>
            </Container>
            <Footer/>
        </div>
    );
}
