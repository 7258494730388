import {Constants} from "../constants/constants";

export const initialState = {
    professions: [],
    loading: false
};

export default function global(state = initialState, action) {
    switch (action.type) {

        case Constants.PROFESSION_BEGIN:
            return { ...state, loading: true }

        case Constants.PROFESSION_SUCCESS:
            return { ...state, professions: action.payload.professions, loading: false }

        default:
            return state;
    }
}
