import React, {useState} from "react"
import LoginForm from "../components/auth/LoginForm"
import RegisterForm from "../components/auth/RegisterForm"
import ForgotForm from "../components/auth/ForgotForm"
import VerificationForm from "../components/auth/VerificationForm"
import ChangePasswordForm from "../components/auth/ChangePasswordForm"

export default ({view = "login", ...res}) => {
    const [viewName, setView] = useState(view);

    switch (viewName) {
        case 'register':
            return <RegisterForm onChangeView={setView}/>;
        case 'set-password':
            return <ChangePasswordForm token={res.match.params.token}/>;
        case 'forgot':
            return <ForgotForm onChangeView={setView}/>;
        case 'verification':
            return <VerificationForm onChangeView={setView}/>;
        default:
            return <LoginForm onChangeView={setView}/>;
    }

}
