import {Network} from "./Network"

export class GameService {

    static initGame(app, level, artist) {
        const withTime = localStorage.getItem('with-time') || 'false';
        return Network.post(true, '/game/create', { app, level, artist, withTime});
    }

    static getBySlug(slug) {
        return Network.fetch(true, `/game/${slug}`);
    }

    static addHint(gameSlug, artWorkId) {
        return Network.post(true, `/game/${gameSlug}/hint`, { artWorkId });
    }

    static select(gameSlug, artWorkId) {
        return Network.post(true, `/game/${gameSlug}/select`, { artWorkId });
    }

    static timeout(gameSlug) {
        return Network.post(true, `/game/${gameSlug}/timeout`, { });
    }
}
