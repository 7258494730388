export const WIN = require('../assets/sounds/win.mp3');
export const LOSE = require('../assets/sounds/lose.mp3');
export const OVER = require('../assets/sounds/mouseover.mp3');
export const SELECTED = require('../assets/sounds/selected.mp3');
export const FAIL = require('../assets/sounds/fail.mp3');

export const play = async (sound) => {
    const audio = new Audio(`${sound}`);
    await audio.play();
    return audio;
}

export const stop = (audio) => {
    audio.pause();
    audio.currentTime = 0;
}

export const isPlaying = (audio) => {
    return audio && !audio.paused;
}
