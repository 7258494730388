import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {AuthService} from "../service/authService"
import {setMetadata} from "../store/actions/global"
import {Alert, Button, Col, Form, Row} from "react-bootstrap"
import {setUser} from "../store/actions/auth"

export default () => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth);

    const [nick, setNick] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();

        setError(null);
        AuthService.saveProfile({
            nick,
            name,
            password
        }).then((response) => {
            if(response.result) {
                setSuccess("The data was updated correctly");
                dispatch(setUser(response.user))
            } else {
                setError(response.message);
            }
        })
    }

    useEffect(() => {
        dispatch(setMetadata({
            title: `Profile`,
            backLink: `/`
        }));
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if(user) {
            setNick(user.nick);
            setName(user.name);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [user]);

    return (
        <Row className="profile-page">
            <Col xs={12} md={{ span: 6, offset: 3 }}>
                {
                    error && <Alert variant={'danger'}>{error}</Alert>
                }
                {
                    success && <Alert variant={'success'}>{success}</Alert>
                }
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            value={name}
                            onChange={e => setName(e.target.value)}
                            type="text"
                            placeholder="Enter name" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Nick</Form.Label>
                        <Form.Control
                            value={nick}
                            onChange={e => setNick(e.target.value)}
                            type="text"
                            placeholder="Enter nick name" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            placeholder="Password" />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Form>
            </Col>
        </Row>
    );

}
