import {Constants} from "../constants/constants";
import {getToken, removeToken, setToken} from "../actions/auth"

export const initialState = {
    token: getToken(),
    tmpEmail: "",
    user: null
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case Constants.AUTH_LOGOUT:
            removeToken();
            return { ...state, token: null, user: null }

        case Constants.AUTH_LOGIN:
            setToken(action.token)
            return { ...state, token: action.token, user: action.token }

        case Constants.AUTH_SET_USER:
            return { ...state, user: action.user }

        case Constants.AUTH_SET_TMP_EMAIL:
            return { ...state, tmpEmail: action.email }

        default:
            return state
    }
}
