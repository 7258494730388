export const Constants = {
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    AUTH_LOGIN: 'AUTH_LOGIN',
    AUTH_SET_USER: 'AUTH_SET_USER',
    AUTH_SET_TMP_EMAIL: 'AUTH_SET_TMP_EMAIL',
    LOADING: 'LOADING',
    SET_META: 'SET_META',

    GAME_PLAY: 'GAME_PLAY',
    GAME_STOP: 'GAME_STOP',
    GAME_HOVER: 'GAME_HOVER',
    GAME_TIMEOUT: 'GAME_TIMEOUT',

    GAME_BEGIN: 'GAME_BEGIN',
    GAME_SUCCESS: 'GAME_SUCCESS',
    GAME_FAILURE: 'GAME_FAILURE',

    GAME_HINT_INIT: 'GAME_HINT_INIT',
    GAME_HINT_SUCCESS: 'GAME_HINT_SUCCESS',
    GAME_HINT_HIDE: 'GAME_HINT_HIDE',

    GAME_SELECTED_INIT: 'GAME_SELECTED_INIT',
    GAME_SELECTED_SUCCESS: 'GAME_SELECTED_SUCCESS',

    PROFESSION_BEGIN: 'PROFESSION_BEGIN',
    PROFESSION_SUCCESS: 'PROFESSION_SUCCESS',
    PROFESSION_FAILURE: 'PROFESSION_FAILURE',

    APP_BEGIN: 'APP_BEGIN',
    APP_SUCCESS: 'APP_SUCCESS',
    APP_FAILURE: 'APP_FAILURE',

    LEVEL_BEGIN: 'LEVEL_BEGIN',
    LEVEL_SUCCESS: 'LEVEL_SUCCESS',
    LEVEL_FAILURE: 'LEVEL_FAILURE',

};
