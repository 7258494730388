import React, {useEffect} from "react"
import {Link, useHistory} from "react-router-dom"
import {GameService} from "../../service/gameService"
import {useParams} from "react-router"
import {Col, Container, Row} from "react-bootstrap"
import Image from "../global/Image"
import {getResultArtWorks} from "../../helpers/game"
import TimeIndicator from "../global/TimeIndicator";
import {useDispatch, useSelector} from "react-redux"
import {play} from "../../store/actions/game"

export default ({ game }) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const globalState = useSelector(state => state.global)

    const {title, backLink} = globalState;
    let { professionSlug, appSlug } = useParams();

    const initGame = ()  => {
        GameService.initGame(appSlug, game.level.id, game.artist.id).then((response) => {
            if(response?.game) {
                history.push(`/app/${professionSlug}/${appSlug}/${response.game.slug}`, response.game);
            }
        })
    }

    const onClick = (artWork) => {
        dispatch(play(artWork));
    }

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, [game])

    const cardData = getResultArtWorks(game)

    return (
        <div className="game-result">
            <div className="game-status">
                {
                    backLink &&
                    <Link to={backLink} id="back-link">
                        <i className="fa fa-chevron-circle-left"/>
                    </Link>
                }
                <div className="game-title" dangerouslySetInnerHTML={{ __html: title }}/>
                <div className="score">
                    <i className="fa fa-star"/>
                    <span className="score-point">{game.points}</span>
                </div>
            </div>
            <Container>
                <Row>
                    <Col>
                        <TimeIndicator/>
                    </Col>
                </Row>

                <h2>
                    { game.status === 'TIMEOUT' ? 'Sorry! your time is up': '' }
                    { game.status === 'LOSE' ? 'Sorry! You lost ': '' }
                    { game.status === 'WIN' ? 'Congratulations!': '' }
                </h2>
                {
                    game.status === 'WIN' && <h3>
                        Your score for { game.artist.name }
                        {
                            (game.score > game.points) ? ` is still ${game.score}` : ` is now ${game.points}`
                        }
                        </h3>
                }
                {
                    game.status !== 'WIN' && <h3>Your score for { game.artist.name } is still { game.score }</h3>
                }
                <Row className="finish-options">
                    <div onClick={initGame} className="btn btn-primary">Play Again</div>
                    <Link className="btn btn-primary" to={`/app/${professionSlug}/${appSlug}/`}>Change Artist/Level</Link>
                    <Link className="btn btn-primary" to={`/app/${professionSlug}/`}>Go to {game.profession.name} Menu</Link>
                    <Link className="btn btn-primary" to={`/`}>Go to Main Menu</Link>
                </Row>
                <Row>
                    {
                        cardData.map((artWork, idx) => {
                            if(artWork) {
                                return (
                                    <div onClick={() => onClick(artWork)} key={idx} className={`game-option col-xl-3 col-sm-6`}>
                                        <div className="artwork-option">
                                            <Image title={artWork.title} src={artWork.image} alt={artWork.title}/>
                                        </div>
                                        <h6 className="artwork-title" title={artWork.title}>{artWork.title}</h6>
                                        <div className="artwork-details">{artWork.date}</div>
                                    </div>
                                );
                            }
                            return null;
                        })
                    }
                </Row>
            </Container>
        </div>
    );
}
