import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import '../sass/home.scss'
import {setMetadata} from "../store/actions/global"
import { useParams} from "react-router";
import {findByProfession} from "../store/actions/app"
import ImageOption from "../components/global/ImageOption"

export default () => {
    const dispatch = useDispatch();
    let { professionSlug } = useParams();

    const state = useSelector(state => state.app);

    const { apps, profession } = state;

    useEffect(() => {
        dispatch(findByProfession(professionSlug))
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        dispatch(setMetadata({
            title: profession?.name ?? 'Mandagames',
            backLink: '/',
            favicon: profession?.icon ?? 'files/painting-icon.ico'
        }))
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [apps]);

    return (
        <div className="app-page">
            <ol className="row list-unstyled text-center">
                {apps.map((app, idx) => {
                    return (
                        <li key={idx} className="col-xl-3 col-sm-4 col-6 menu-option">
                            <ImageOption title={app.name} src={app.splash} link={`/app/${professionSlug}/${app.slug}`}/>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
}
