import {Alert, Button, Col, Form, Row} from "react-bootstrap"
import React, {useEffect, useState} from "react"
import {AuthService} from "../../service/authService"
import {login, setTmpEmail} from "../../store/actions/auth"
import {useDispatch} from "react-redux"
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import {setMetadata} from "../../store/actions/global"
import {useHistory} from "react-router-dom"


export default ({ onChangeView }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [error, setError] = useState(null);
    const [sending, setSending] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [google, setGoogle] = useState(false);
    const [face, setFace] = useState(false);
    const [nick, setNick] = useState('');

    useEffect(() => {
        dispatch(setMetadata({
            title: `Login`,
            backLink: `/`
        }));
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    const processResponse = (response) => {
        setSending(false);
        if(response.result) {
            if(response.hasOwnProperty('verified') && !response.verified) {
                dispatch(setTmpEmail(email));
                onChangeView('verification')
            } else {
                dispatch(login(response.token, response.user));
                history.push('/')
            }

        } else {
            setError(response.message);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSending(true);

        AuthService.login({
            email,
            password
        }).then(processResponse)
    }

    const handleSocialSubmit = (event) => {
        event.preventDefault();
        setSending(true);
        if(face) {
            AuthService.facebookLogin({
                token: face.accessToken,
                nick,
                type: 'register'
            }).then(processResponse)
        } else {
            AuthService.googleLogin({
                token: google,
                nick,
                type: 'register'
            }).then(processResponse)
        }
    }

    const onFacebookSignIn = (facebook) => {
        if(facebook.status !== 'unknown'){
            AuthService.checkEmail({email: facebook.email}).then((response) => {
                if(response?.exist) {
                    AuthService.facebookLogin({
                        token: facebook.accessToken,
                        type: 'login'
                    }).then(processResponse)
                } else {
                    setFace(facebook);
                }
            })
        }
    }

    const onGoogleSignIn = (googleUser) => {
        const id_token = googleUser.getAuthResponse().id_token;
        AuthService.checkEmail({ email: googleUser.profileObj.email }).then((response) => {
            if(response?.exist) {
                AuthService.googleLogin({
                    token: id_token,
                    type: 'login'
                }).then(processResponse)
            } else {
                setGoogle(id_token);
            }
        })
    }

    const showForm = google || face;

    return (
        <>
            { error && <Alert variant={'danger'}>{error}</Alert> }
            <Row className="auth-page">
                {
                    showForm &&
                    <Col className="social-button mb-2" xs={12} md={{ span: 6, offset: 3 }}>
                        <Form onSubmit={handleSocialSubmit}>
                            <Form.Group>
                                <Form.Label>Nick</Form.Label>
                                <Form.Control
                                    value={nick}
                                    onChange={e => setNick(e.target.value)}
                                    type="text"
                                    placeholder="Enter nickname" />
                            </Form.Group>
                            <Button variant="primary" type="submit" disabled={sending}>
                                { sending ? "sending" : "Send" }
                            </Button>
                        </Form>
                    </Col>
                }
                {
                    !showForm &&
                        <>
                            <Col className="social-button text-center mb-2" xs={12} md={{ span: 6, offset: 3 }}>
                                <FacebookLogin
                                    size="small"
                                    disableMobileRedirect={true}
                                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                    fields="name,email,picture"
                                    authType="rerequest"
                                    callback={onFacebookSignIn} />
                            </Col>
                            <Col className="social-button text-center mb-2" xs={12} md={{ span: 6, offset: 3 }}>
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    buttonText="LOGIN WITH GOOGLE"
                                    onSuccess={onGoogleSignIn}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </Col>
                            <Col xs={12} md={{ span: 6, offset: 3 }}>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            type="email"
                                            placeholder="Enter email" />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            type="password"
                                            placeholder="Password" />
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Login
                                    </Button>
                                    <span className="forgot-link btn-link" onClick={() => onChangeView('forgot')}>Forgot password?</span>
                                    <div>
                                        <span className="btn-link" onClick={() => onChangeView('register')}>Not Registered yet. Go to registration</span>
                                    </div>
                                </Form>
                            </Col>
                        </>
                }
            </Row>
        </>
    );
}
