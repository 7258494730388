import {Alert, Button, Col, Form, Row} from "react-bootstrap"
import React, {useEffect, useState} from "react"
import {AuthService} from "../../service/authService"
import {useDispatch} from "react-redux"
import {login} from "../../store/actions/auth"
import {setMetadata} from "../../store/actions/global"
import {useHistory} from "react-router-dom"

export default ({ token }) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);


    const handleSubmit = (event) => {
        event.preventDefault();

        setError(null);
        if(password !== confirmPassword) {
            return setError("The password does not match");
        }
        AuthService.changePassword({
            password,
            token
        }).then((response) => {
            if(response.result) {
                dispatch(login(response.token, response.user));
                history.push(`/`);
            } else {
                setError(response.message);
            }
        })
    }

    useEffect(() => {
        dispatch(setMetadata({
            title: `Change Password`,
            backLink: `/`
        }));
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (
        <Row className="auth-page">
            <Col xs={12} md={{ span: 6, offset: 3 }}>
                {
                    error && <Alert variant={'danger'}>{error}</Alert>
                }
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            placeholder="Password" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            type="password"
                            placeholder="Confirm Password" />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Accept
                    </Button>
                </Form>
            </Col>
        </Row>
    );
}
