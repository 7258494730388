import {Constants} from "../constants/constants";

export const initialState = {
    levels: [],
    app: null
};

export default function global(state = initialState, action) {
    switch (action.type) {

        case Constants.LEVEL_BEGIN:
            return { ...state, ...initialState }

        case Constants.LEVEL_SUCCESS:
            return { ...state, levels: action.payload.levels, app: action.payload.app }

        default:
            return state;
    }
}
