import logo from '../../assets/images/no-pic.jpeg';
import React, {useState} from "react";
import {Dropdown, Modal} from "react-bootstrap";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux"
import {logout} from "../../store/actions/auth"
import Image from "./Image"
import LeaderBoard from "../leaderboard/LeaderBoard";

export default () => {
    const {user} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)

    let history = useHistory();

    const exit = async () => {
        dispatch(logout());
    }

    return (
        <>
            <Dropdown id="user-dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                    {
                        (user && user.picture) ?
                            <Image src={user.picture} title={user.nick} alt={user.nick}/> :
                            <img src={(user && user.picture) ? user.picture : logo} alt="Guest"/>
                    }
                    <span className="caret"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        !user &&
                        <>
                            <Dropdown.Item onClick={() => history.push('/login')}>Guest</Dropdown.Item>
                        </>
                    }
                    {
                        user &&
                        <>
                            <Dropdown.Item onClick={() => history.push('/profile')}>{user.nick}</Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)}>Leaderboard</Dropdown.Item>
                            <Dropdown.Item onClick={exit}>Sign out</Dropdown.Item>
                        </>
                    }
                </Dropdown.Menu>
            </Dropdown>
            <Modal className="leaderboard" show={show} onHide={()=>setShow(false)}>
                <Modal.Header closeButton>
                    <LeaderBoard/>
                </Modal.Header>
            </Modal>
        </>
    );
}
