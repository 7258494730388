import {Constants} from "../constants/constants";
import {setAction} from "./fetchActions"
import {LevelService} from "../../service/levelService"

export function findByApp(appSlug) {
    return (dispatch) => {
        dispatch(setAction(Constants.LEVEL_BEGIN))

        return LevelService.findBySlug(appSlug)
            .then((json) => dispatch(setAction(Constants.LEVEL_SUCCESS, json)))
            .catch((error) => dispatch(setAction(Constants.LEVEL_FAILURE ,error)))
    }
}
