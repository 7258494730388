import React from "react";

export default () => {
    return (
        <section><p>TERMS &amp; CONDITIONS<br/>This document contains legal information, including the Terms and
            Conditions and the Code of Conduct applicable to the online services provided by MANDAGAMES (hereinafter the
            “Company”) in connection with any Mandagames game or application (“Services”) . Please read them
            carefully.<br/>The Services are the property of the Company. The Company provides access to the Services
            Service provided hereon subject to your compliance with the following Terms and Conditions constitute an
            agreement between you and the Company (“Agreement”) that governs the relationship between you and the
            Company with respect to your use of Services. It is important to read carefully and agree to the following
            Terms and Conditions before the use of the Services. The Company may at any point make changes and
            amendments to the present Terms and Conditions. You are responsible to keep track of these changes. Use of
            the Services after these amendments constitutes acceptance of the new Terms and Conditions. If the user at
            any point violates these terms, the Company may take all necessary actions, including termination of user’s
            account, without prior notice (See Termination as well).<br/>You may be thirteen or over to use the Services
            or register as a Member. If you are between the ages of thirteen and eighteen, you declare that your legal
            guardian has reviewed and agrees to these Terms and Condition. By agreeing to these Terms and Conditions or
            by using the Services, you represent and warrant that you have the authority, right and capacity to enter
            into this Agreement and to abide by all of the Terms and Conditions of this Agreement.<br/>Other Agreements:
            Please note that, if you sign up for one or more features of the Services that are provided by third parties
            or any widgets and applications that may be listed or marketed on the Services, then you may be required to
            agree to terms of services with those third parties as well. Any such terms of services are different from
            and in addition to this Agreement.<br/>DESCRIPTION OF ONLINE SERVICES<br/>The Services include, but not
            limited to, any service or content the Company provides to you, including any materials displayed or
            performed on the Service. The Company may change, suspend or discontinue any feature or content. The Company
            may also impose limits on certain features and services or restrict the access to parts or all of the
            Services without any notice or liability.<br/>USE OF SERVICE<br/>Your license to use the Services is limited
            by these Terms of Service. All software made available to access the Services is the copyrighted work of the
            Company and/or its licensors. The Company hereby grants you a personal, non-exclusive, non-transferable,
            revocable, limited scope license to use the Software solely for the purpose of viewing and using the
            applicable Services and for no other purpose whatsoever.<br/>TRADEMARKS AND INTELLECTUAL PROPERTY
            RIGHTS<br/>All Services material, including, but not limited to, text, data, graphics, logos, button icons,
            images, audio clips, video clips, links, digital downloads, data compilations, and software is owned,
            controlled by, licensed to, or used with permission by the Company and is protected by copyright, trademark,
            and other intellectual property rights. The Services material is made available solely for your personal,
            non-commercial use and may not be copied, reproduced, republished, modified, uploaded, posted, transmitted,
            or distributed in any way, including by e-mail or other electronic means, without the express prior written
            consent of the Company in each instance. You may download material intentionally made available for
            downloading through the Services for your personal, non-commercial use only, provided that you keep intact
            any and all copyright and other proprietary notices that may appear on such materials.<br/>LIMITED LICENSE
            BY THE COMPANY<br/>The Company grants you a limited, non-sublicensable license to access and use the
            Services and to view, copy and print portions of the Services. Such license is subject to these Terms and
            Conditions, and specifically conditioned upon the following: (i) you may only view, copy and print portions
            of the Services for your own informational, personal and non-commercial use; (ii) you may not modify or
            otherwise make derivative uses of the Services, or any portion thereof; (iii) you may not remove or modify
            any copyright, trademark, or other proprietary notices that have been placed in the Services; (iv) you may
            not use any data mining, robots or similar data gathering or extraction methods; (v) you may not use the
            Services other than for their intended purpose; and (vi) you may not reproduce, prepare derivative works
            from, distribute or display the Services (except for page caching), except as provided herein.<br/>Except as
            expressly permitted above, any use of any portion of the Services without the prior written permission of
            the Company is strictly prohibited and will terminate the license granted herein. Any such unauthorized use
            may also violate applicable laws. Unless explicitly stated herein, nothing in these Terms and Conditions may
            be construed as conferring any license to intellectual property rights. This license is revocable at any
            time.<br/>You represent and warrant that your use of the Services will be consistent with this license and
            will not infringe or violate the rights of any other party or breach any contract or legal duty to any other
            parties, or violate any applicable law. You expressly agree to indemnify the Company against any liability
            to any person arising out of your use of Services not in accordance with these Terms and Conditions.<br/>To
            request permission for uses of the Services not included in the foregoing license, you may write to the
            Company at <a href="mailto:support@mandagames.com">support@mandagames.com</a><br/>CODE OF CONDUCT<br/>The
            following rules, policies, and disclaimers shall govern and/or apply to your use of the Services.<br/>You
            agree, by using the Services, that shall not:<br/>1. Create an account with or access the Service if you are
            under the age of 13;<br/>2. Upload, post, transmit or otherwise disseminate material that is vulgar,
            indecent, obscene, pornographic, sexual or that is, in a reasonable person's view, otherwise offensive or
            objectionable;<br/>3. Libel, ridicule, defame, mock, stalk, intimidate, threaten, harass, or abuse anyone,
            hatefully, racially, ethnically or in any other manner;<br/>4. Upload or transmit (or attempt to upload or
            transmit) files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files or data,
            or any other similar software or programs that may damage the operation of the Service or the computers of
            other users of the Service;<br/>5. Advertise, solicit or transmit any commercial advertisements, including
            chain letters, junk e-mail or repetitive messages (spim or spam) to anyone;<br/>6. Violate the contractual,
            personal, intellectual property or other rights of any party including by using, uploading, transmitting,
            distributing, or otherwise making available any information or material made available through the Service
            in any manner that infringes any copyright, trademark, patent, trade secret, or other right of any party
            (including rights of privacy or publicity);<br/>7. Create false personas, multiple identities, multiple user
            accounts, set up an account on behalf of someone other than yourself, use bots or other automated software
            programs to defraud or which otherwise violate these Terms and Conditions and/or the terms of service of any
            third-party applications or social networks through which the Service is accessed;<br/>8. Attempt to obtain
            passwords or other private information from other members including personally identifiable information
            (whether in text, image or video form), identification documents, or financial information;<br/>9. Upload or
            transmit (or attempt to upload or to transmit), without the Company ’s express permission, any material that
            acts as a passive or active information collection or transmission mechanism, including, without limitation,
            clear graphics interchange formats ("gifs"), 1x1 pixels, web bugs, cookies or other similar devices
            (sometimes referred to as "spyware," "passive collection mechanisms" or "pcms");<br/>10. Improperly use
            support channels or complaint buttons to make false reports to the Company ;<br/>11. Develop, distribute,
            use, or publicly inform other members of "auto" software programs, "macro" software programs or other "cheat
            utility" software programs or applications;<br/>12. Exploit, distribute or publicly inform other members of
            any game error, miscue or bug which gives an unintended advantage;<br/>13. Deal with Coins in a manner that
            violates these Terms, including transferring Coins to other individuals, parties, or entities, selling or
            re-selling Virtual Currency or virtual goods or fraudulently obtaining or acquiring Virtual Currency or
            other products or services;<br/>14. Rent, lease, sell, trade, gift, bequeath or otherwise transfer your
            account to anyone without the Company ’s written permission;<br/>15. Access or use an account which has been
            rented, leased, sold, traded, gifted, bequeathed, or otherwise transferred from the account creator without
            the Company ’s written permission;<br/>16. Engage in any fraudulent activity with respect to payment methods
            or advertiser tracking mechanisms;<br/>17. Violate any applicable laws or regulations, or encourage or
            promote any illegal activity including, but not limited to, copyright infringement, trademark infringement,
            defamation, invasion of privacy, identity theft, hacking, cracking or distribution of counterfeit software,
            or cheats or hacks for the Services;<br/>18. Attempt to use the Services on or through any service that is
            not authorized by the Company. Any such use is at your own risk and may subject you to additional or
            different terms. The Company takes no responsibility for your use of the Services through any service that
            is not authorized by it;<br/>19. Post or communicate any person's real-world personal information using the
            Services;<br/>20. Attempt to interfere with, hack into or decipher any transmissions to or from the servers
            for the Services; or<br/>21. Interfere with the ability of others to enjoy using the Service, including
            disrupt, overburden or aid the disruption or overburdening of the Service servers, or take actions that
            interfere with or materially increase the cost to provide the Service for the enjoyment of all its
            users.<br/>When we provide services involving user-posted messages or content, we do not review every
            posting, nor do we confirm the accuracy or validity of information posted. We do not actively monitor the
            contents of the postings, nor are we responsible for the content of any postings. We do not vouch for, nor
            do we warrant the validity, accuracy, completeness, or usefulness of any message or information posted. The
            contents of the postings do not represent the views of the Company, its subsidiaries, or any person or
            property associated with the Company, or any other service in the Company's family of Services. If you feel
            that any posting is objectionable, we encourage you to contact us by email at <a
                href="mailto:support@mandagames.com">support@mandagames.com</a>. We will make every effort to remove
            objectionable content if we deem removal to be warranted. Please understand that removal or editing of any
            posting is a manual process and might not occur immediately. We reserve the right to remove (or not) any
            posted message or content for any (or no) reason whatsoever. You remain solely responsible for your
            postings, and you agree to indemnify and hold harmless the Company and its agents with respect to any claim
            based upon the transmission of your message(s) and/or posting(s). Posting of advertisements, chain letters,
            pyramid schemes, solicitations, and the like, are inappropriate and forbidden on the Services (including
            bulletin boards and chat rooms).<br/>USER ACCOUNTS<br/>If at any point Service require to open an account
            you agree to provide accurate, current and complete data. You also agree not to pretend to be someone else
            when using the Services. Names that are offensive in any way or are meant to cause confusion such as
            webmaster, admin, web_master, etc. will not be accepted as user names.<br/>Users are liable to the Company
            and its affiliates for any loss or damage that may arise from illegal or harmful use of their account and
            Services as well as from an illicit use or use non pursuant to the present Terms and Conditions.</p>
            <p>ACCOUNT SECURITY<br/>You understand and agree that you are the sole responsible of keeping the security
                of your account. You also agree to immediately notify the Company of any unauthorized use of your
                password or account or any other breach of security. The Company is under no circumstances liable for
                any loss or damage arising from your failure to comply with this Section or any use of your password or
                account whether authorized by you or not. You are by all means liable to the Company for any loss that
                derives from any use of your account even an unauthorized one.<br/>PAYMENT OPTIONS<br/>You may purchase
                additional optional games using your credit card via PayPal.</p>
            <p>USER DATA<br/>The Company collects the data that you have submitted to your profile from the social
                networking site that you are already registered to and allows you access to the Service. The Company
                collects only the submitted data / profile data (like name) that are necessary for the proper function
                of the Service i.e. in order to keep track of high scores, or in order to notify you about new products
                etc.<br/>Technical information such as your IP address may be recorded for the proper operation of the
                Service.<br/> Upon invitation of your friends to join the Services, we may collect and record additional
                data such as their e-mail addresses and names. By using the Services you consent and permit the Company
                to collect, record and use your data according to these Terms and Conditions and as they may be amended
                from time to time.<br/> Your data may remain to our servers for a longer period than your account. So if
                you terminate your account some of your data may be kept in our systems. Feel free to contact with us
                for any further information regarding your personal information. Please read as well all about our
                Privacy Policy<br/>WARRANTY DISCLAIMER<br/>YOU AGREE THAT YOUR USE OF THE SERVICES SHALL BE AT YOUR SOLE
                RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, THE COMPANY , ITS OFFICERS, DIRECTORS, EMPLOYEES, AND
                AGENTS DISCLAIM ALL WARRANTIES, EXPLICIT OR IMPLIED, IN CONNECTION WITH THE SERVICE AND YOUR USE THEREOF
                INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE OR
                NON-INFRINGEMENT, USEFULNESS, AUTHORITY, ACCURACY, COMPLETENESS, AND TIMELINESS. THE COMPANY MAKES NO
                WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT OF THE SERVICE OF THE
                CONTENT OF ANY SITES LINKED TO THE SERVICES AND ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY:<br/>(I)
                ERRORS, MISTAKES, OR INACCURACIES OF CONTENT;<br/>(II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICE;<br/>(III) ANY UNAUTHORIZED ACCESS TO
                OR USE OF THE COMPANY ’S SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
                INFORMATION STORED THEREIN;<br/>(IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                SERVICE;<br/>(V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH
                THE SERVICE BY ANY THIRD PARTY; OR<br/>(VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR
                DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
                OTHERWISE MADE AVAILABLE VIA THE SERVICES.</p>
            <p>IN NO EVENT WILL THE COMPANY, ITS DIRECTORS, OFFICERS, AGENTS, CONTRACTORS, PARTNERS AND EMPLOYEES, BE
                LIABLE TO YOU OR ANY THIRD PERSON FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR
                CONSEQUENTIAL DAMAGES WHATSOEVER INCLUDING ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE
                SERVICE OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE SERVICES, WHETHER BASED ON
                WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF
                THE POSSIBILITY OF THESE DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST
                EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. YOU SPECIFICALLY ACKNOWLEDGE THAT THE COMPANY
                SHALL NOT BE LIABLE FOR USER SUBMISSIONS OR DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT BY ANY THIRD PARTY
                AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.</p>
            <p>THE COMPANY MAY PROVIDE LINKS, THIRD PARTY CONTENT, WIDGETS, APPLICATIONS, POINTERS TO INTERNET SITES
                MAINTAINED BY THIRD PARTIES AND MAY, FROM TIME TO TIME PROVIDE THIRD PARTY MATERIALS ON THE SERVICES.
                NEITHER THE COMPANY, IT’S SUBSIDIARY COMPANIES AND THEIR AFFILIATES, ENDORSE, TAKE RESPONSIBILITY FOR,
                OPERATE OR CONTROL IN ANY RESPECT ANY INFORMATION, PRODUCTS, OR SERVICES ON THESE THIRD PARTY SITES. THE
                MATERIALS ON THE SERVICEE AND THE THIRD PARTY SITES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITH NO
                REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT. YOU ASSUME
                TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SERVICES.<br/>THE COMPANY AND ITS AFFILIATES THEY ARE
                NOT RESPONSIBLE OR LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR
                OTHER DAMAGES ARISING OUT OF OR RELATING IN ANY WAY TO THE SERVICEE, OR ANY THIRD PARTY SITES. YOUR SOLE
                REMEDY FOR DISSATISFACTION WITH THE SERVICEE, AND/OR THIRD PARTY SITES IS TO STOP USING THE SERVICE(S)
                CONCERNED AND/OR THOSE SERVICES.<br/>THE COMPANY DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE
                SERVICES, THE SERVICES AND/OR ANY THIRD PARTY SITES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS
                WILL BE CORRECTED, OR THAT THE SERVICES OR ANY THIRD PARTY SITES, OR THE SERVERS THAT MAKE THEM
                AVAILABLE, ARE OR WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE COMPANY DOES NOT WARRANT OR
                MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS ON THE SERVICES,
                THE E SERVICES, OR ANY THIRD PARTY SITES IN TERMS OF THEIR CORRECTNESS, ACCURACY, TIMELINESS,
                RELIABILITY, OR OTHERWISE. YOU (AND NOT THE COMPANY) ASSUME THE ENTIRE COST OF ALL NECESSARY
                MAINTENANCE, REPAIR, OR CORRECTION OF ANY SUCH MATERIALS. FOR THE AVOIDANCE OF DOUBT, THE COMPANY MAKES
                NO REPRESENTATIONS OR WARRANTIES REGARDING HOW OFTEN THE MATERIALS OR INFORMATION POSTED ON THE SERVICES
                WILL BE UPDATED (IF AT ALL).<br/>THE SERVICES MAY BE SERVED OR HOSTED BY OTHER SITES. THESE OTHER SITES
                MAINTAIN THEIR OWN TERMS OF USE AND PRIVACY POLICY. THE PRESENT TERMS OF SERVICE DO NOT APPLY TO THOSE
                SITES. IT IS UP TO YOU TO REVIEW THOSE POLICIES YOURSELF.<br/> THE AVAILABILITY OF ANY SERVICE ON A
                SOCIAL NETWORKING SITE DOES NOT INDICATE ANY RELATIONSHIP OR AFFILIATION BETWEEN THE COMPANY AND SUCH
                SOCIAL NETWORKING SITE.<br/>YOU AGREE TO INDEMNIFY AND HOLD, AND EACH OF ITS DIRECTORS, OFFICERS,
                AGENTS, CONTRACTORS, PARTNERS AND EMPLOYEES, HARMLESS FROM AND AGAINST ANY LOSS, LIABILITY, CLAIM,
                DEMAND, DAMAGES, COSTS AND EXPENSES, INCLUDING REASONABLE ATTORNEY'S FEES, ARISING OUT OF OR IN
                CONNECTION WITH<br/>(I) YOUR USE OF AND ACCESS TO THE SERVICE;<br/>(II) YOUR VIOLATION OF ANY TERM OF
                THESE TERMS;<br/>(III) YOUR VIOLATION OF ANY THIRD PARTY RIGHT, INCLUDING WITHOUT LIMITATION ANY
                COPYRIGHT, PROPERTY, OR PRIVACY RIGHT;<br/>(IV) ANY CLAIM THAT A USER SUBMISSIONS MADE BY YOU HAS CAUSED
                DAMAGE TO A THIRD PARTY; OR<br/>(V) ANY USER CONTENT YOU POST OR SHARE ON OR THROUGH THE SERVICE.</p>
            <p>VOID WHERE PROHIBITED<br/>Although these Services are accessible worldwide, not all products or services
                discussed or referenced on these Services are available to all persons or in all geographic locations.
                The Company reserves the right to limit, in its sole discretion, the provision and quantity of any
                product or service to any person or geographic area it so desires. Any offer for any product or service
                made in these Services is void where prohibited.</p>
            <p>TERMINATION<br/> The Company may terminate or suspend any and all Services and any registered account
                immediately, without prior notice or liability, for any reason, including if you breach any of the terms
                or conditions of the Terms and Conditions. Upon termination of your account, your right to use the
                Services will immediately cease. If you wish to terminate your account, you may simply discontinue using
                the Services. All provisions of the Terms and Conditions which by their nature should survive
                termination shall survive termination, including, without limitation, ownership provisions, warranty
                disclaimers, indemnity and limitations of liability.</p>
            <p>APPLICABLE LAW - MISCELLANEOUS<br/>The present Terms and Conditions, as well as any modification,
                alteration or amendment on them are governed by Argentine Law and to the international treaties and
                agreements signed by Argentina, without regard to principles of conflict of laws and regardless of your
                location. By accepting these Terms you accept that they are subject to the jurisdiction of Argentine
                Courts.<br/>All the above-mentioned terms are essential. Should any of the terms become against the law,
                becomes inactive without affecting the rest of the terms.<br/>This is an agreement between the Company
                and you. In case Company merges or is taking over wholly or partly, the present Terms of Service remain
                valid and bind you with the successor of the Company.<br/>None modification of these will be regarded as
                valid until it written and embodied to the present Terms and Conditions. The Company may modify the
                terms of this Agreement at any time in its sole discretion by posting a revised Agreement or, in the
                case of a material modification, by posting notice of such modification on a page of this Service
                entitled "Legal Notices" or "Legal Information" (or similar title) before the modification takes effect.<br/> Disputes
                arising between users and the Company should firstly be negotiated and tried to be solved out of
                court.<br/>If users disagree with the above mentioned Terms should not use the site and services of the
                Company.</p>
        </section>
    );
}