import {Constants} from "../constants/constants";

export const initialState = {
    apps: [],
    profession: null
};

export default function global(state = initialState, action) {
    switch (action.type) {

        case Constants.APP_BEGIN:
            return { ...state, ...initialState }

        case Constants.APP_SUCCESS:
            return { ...state, apps: action.payload.apps, profession: action.payload.profession }

        default:
            return state;
    }
}
