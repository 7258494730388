import React, {useEffect} from "react"
import {useDispatch} from "react-redux"
import '../sass/home.scss'
import {setMetadata} from "../store/actions/global"
import {useParams} from "react-router";
import Page404 from "./Page404";
import Help from "../components/static/Help";
import Policy from "../components/static/Policy";
import Terms from "../components/static/Terms";
import TermsEdmodo from "../components/static/TermsEdmodo";
import WhatIsMandagames from "../components/static/WhatIsMandagames"

export default () => {
    const dispatch = useDispatch();
    let { slug } = useParams();

    let Page = <Page404/>;
    let title = "404 - Not Found";

    switch (slug) {
        case 'help':
            title = "Help";
            Page = <Help/>;
            break;
        case 'policy-on-data-protection':
            title = "Policy on Data Protection";
            Page = <Policy/>;
            break;
        case 'terms-of-use-edmodo':
            title = "Terms of Use Edmodo";
            Page = <TermsEdmodo/>;
            break;
        case 'terms-of-use':
            title = "Terms of use";
            Page = <Terms/>;
            break;
        case 'what-is-mandagames':
            title = "What is Mandagames?";
            Page = <WhatIsMandagames/>;
            break;
        default:
            title = "Terms of use";
            Page = <Terms/>;
            break;
    }

    useEffect(() => {
        dispatch(setMetadata({
            title: title,
            backLink: '/',
            favicon: 'files/painting-icon.ico'
        }))
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [slug]);

    return Page;
}
