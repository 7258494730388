import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {setMetadata} from "../store/actions/global"
import { useParams } from "react-router";
import Game from "../components/game/Game"
import {findBySlug, stop} from "../store/actions/game"
import {Container} from "react-bootstrap"
import {Helmet} from "react-helmet-async"


export default () => {
    const dispatch = useDispatch();
    const { title } = useSelector(state => state.global);
    let { professionSlug, appSlug, gameSlug } = useParams();

    const state = useSelector(state => state.game);

    const { game } = state;

    useEffect(() => {
        if(gameSlug) {
            dispatch(findBySlug(gameSlug));
        }
        /* eslint-disable react-hooks/exhaustive-deps */
        return () => dispatch(stop())

    }, [gameSlug]);

    useEffect(() => {
        if(game) {
            const title = game.artist.name;
            const date = [game.artist.nationality];
            if(game.artist.date) date.push(game.artist.date);
            dispatch(setMetadata({
                title: `${title} <small>(${date.join(', ')})</small>`,
                backLink: `/app/${professionSlug}/${appSlug}/`
            }));
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    }, [game]);

    return (
        <>
            <Helmet>
                <title>{title.replace(/<[^>]*>?/gm, '')}</title>
            </Helmet>
            <div className={ `game-page ${game ? game.profession.name.toLowerCase() : ''}`}>
                { game && <Game game={game}/> }
            </div>
            <Container>
                <footer className="image-courtesy">
                    <small>Images courtesy of <a rel='noopener noreferrer' href="http://www.wga.hu" target="_blank">www.wga.hu</a></small>
                </footer>
            </Container>
        </>
    );
}
